import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useField, useFormikContext } from "formik";

const DateTimeStrPicker = React.forwardRef(function DateTimeStrPicker(props, ref) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker
          {...field}
          {...props}
          selected={(field.value && new Date(field.value)) || null}
          onChange={val => {
              setFieldValue(field.name, val);
          }}
        ></DateTimePicker>
      </DemoContainer>
    </LocalizationProvider>
  );
});

export default DateTimeStrPicker;