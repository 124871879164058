import * as React from 'react';
import { 
    Grid,
} from '@mui/material';
import { useField } from "formik";
import { YesNoRadio } from '../../Components/YesNoRadio';

const AdditionalDriversStep = React.forwardRef(function AdditionalDriversStep(props, ref) {
    const [field] = useField(props);

    return (
        <Grid container spacing={2}>
            <Grid item container className='section' spacing={1}>
                <Grid item container spacing={1}>
                    <Grid item md={6} xs={12}>
                        <YesNoRadio label="Do you have any additional drivers?" name="additionalDrivers" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

export default AdditionalDriversStep;