import * as React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { VehicleSearch } from "../../Components/VehicleSearch";
import { CustomCheckbox } from "../../Components/Checkbox";
import DatePicker from "../../Components/DatePicker";
import dayjs, { Dayjs } from 'dayjs';
import { 
    Grid,
    Typography,
    Button,
    InputAdornment,
    CircularProgress
} from '@mui/material';
import { useField, useFormikContext } from "formik";

const VehicleStep = React.forwardRef(function VehicleStep(props, ref) {
    const [field] = useField(props);
    const { setFieldValue } = useFormikContext();
    const [isCheckingVehicle, setCheckingVehicle] = React.useState(false);
    
    const checkVehicle = async() => {
        if (field.value.vehicleRegistration) {
            setCheckingVehicle(true);
            const vehicleData = await getVehicleDetails(field.value.vehicleRegistration);
            
            setCheckingVehicle(false);
    
            if (vehicleData.status) {
                props.setVehicleData(vehicleData);

                if (vehicleData.data.lastVehicleHistoryDate) {
                    setFieldValue('purchaseDate', dayjs(vehicleData.data.lastVehicleHistoryDate));
                }
            }
            else {
                props.setVehicleData(null);
                props.notify(0, "Could not find your vehicle details");
            }
        }
    };

    const getVehicleDetails = async(vrn) => {
        var response = {
          'msg' : 'No response from server',
          'status' : false
        };

        await fetch(props.isTesting === true? `https://insurance.courio.co.uk/get2/vehicle.php` : `https://insurance.courio.co.uk/get/vehicle.php`, { 
            method: "POST",
            body: JSON.stringify({
                vrn: vrn,
                api_key: process.env.REACT_APP_API_KEY
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
        .then((response) => response.json())
        .then((responseData) => {
            response = responseData;
        })
        .catch(error => console.warn(error));
    
        return response;
    };

    return (
        <Grid container spacing={2}>
            {props.vehicleData === null ? 
                <Grid item container className='vehicleRegContainer'>
                    <Grid item md={8} xs={12}>
                        <VehicleSearch 
                            name="vehicleRegistration" 
                            label="Enter Van Registration"
                        />
                    </Grid>
                    <Grid item md={4} xs={12} className={props.classes.flex}>
                        <Button
                            startIcon={isCheckingVehicle ? <CircularProgress style={{'color': '#07a9e1'}} size="1rem" /> : null}
                            disabled={isCheckingVehicle}
                            variant="contained"
                            color="primary"
                            className='bordered'
                            onClick={checkVehicle}
                        >
                            {isCheckingVehicle ? 'Checking Vehicle...' : 'Check Vehicle'}
                        </Button>
                    </Grid>
                </Grid>
            : 
                <Grid item container >
                    <Grid item container md={12} xs={12} className={props.classes.vehicleDataContainer}>
                        <Grid item className='vehicleContainer' md={6} xs={12}>
                            <Grid item container className='vehicleStats'>
                                <Grid item container md={3} xs={12} spacing={1} className='iconContainer'>
                                    <span className='icon'><i className='fa fa-car'></i></span>
                                </Grid>
                                <Grid item container md={9} xs={12} spacing={1}>
                                    <Grid item md={12} xs={12}>
                                        <Typography variant="h7" className={props.classes.vehicleRegData}>
                                            <span className={props.classes.vehicleRegHolder}></span>
                                            <b>{field.value.vehicleRegistration !== null ? field.value.vehicleRegistration : 'Not Assigned'}</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Typography variant="h7" className={props.classes.vehicleData}>
                                            {props.vehicleData !== null ? props.vehicleData.vehicleTitleStr : ''}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Typography variant="h7">
                                            {props.vehicleData !== null ? props.vehicleData.vehicleDetailsStr : ''}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container className='vehicleConfirmed'>
                                <Grid item md={12} xs={12} className={props.classes.cbContainer}>
                                    <CustomCheckbox name="vehicleConfirmed" label="Yes, that's my vehicle" />
                                </Grid>
                            </Grid>
                            <Grid item container className='vehicleActions'>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className='bordered smallBtn'
                                    onClick={e => props.setVehicleData(null) }
                                >
                                    Find another vehicle
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    {field.value.vehicleConfirmed ? 
                        <Grid item container md={12} xs={12} className='section' spacing={1}>
                            <Grid item md={12} xs={12}>
                                <Typography className="radioLabel">Vehicle Information</Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <DatePicker name="purchaseDate" disabled={field.value.vehicleNotPurchasedYet} label="Purchase Date" views={['month', 'year']} />
                                <CustomCheckbox name="vehicleNotPurchasedYet" label="Not purchased yet" />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Field 
                                    variant="outlined" 
                                    className={props.classes.fullWidth} 
                                    component={TextField} 
                                    name="vehicleValue" 
                                    label="Vehicle Value"
                                    type="number"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    : '' }
                </Grid>
            }
    </Grid>
    );
});

export default VehicleStep;