import React from "react";
import { FC } from "react";
import FormLabel from '@mui/joy/FormLabel';
import Radio, { radioClasses } from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { useField, useFormikContext } from "formik";
import {
  CheckCircle
} from "@material-ui/icons";

import LicenceTypeList from '../../Data/MV/LicenceType.json';

export const LicenceTypeRadio: FC = (props: any) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    const label = props.label;
    
    return (
        <>
            <Typography className="radioLabel">{label}</Typography>

            <RadioGroup
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(field.name, (event.target as HTMLInputElement).value);
                }}
                overlay
                sx={{
                    flexDirection: 'row',
                    gap: 2,
                    [`& .${radioClasses.checked}`]: {
                    [`& .${radioClasses.action}`]: {
                        inset: -1,
                        border: '3px solid',
                        borderColor: 'primary.500',
                    },
                    },
                    [`& .${radioClasses.radio}`]: {
                    display: 'contents',
                    '& > svg': {
                        zIndex: 2,
                        position: 'absolute',
                        top: '-8px',
                        right: '-8px',
                        bgcolor: 'background.surface',
                        borderRadius: '50%',
                    },
                    },
                }}
            >
                {LicenceTypeList.map((value) => {
                    if (value.DE_SHOW === "Y") {
                        return (
                            <Sheet
                                key={value.DE_CODE}
                                variant="outlined"
                                sx={{
                                    borderRadius: 'md',
                                    boxShadow: 'sm',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    p: 2,
                                    minWidth: 140,
                                }}
                                >
                                <Radio id={value.DE_CODE} value={value.DE_CODE} checked={field.value === value.DE_CODE} checkedIcon={<CheckCircle />} />
                                <FormLabel sx={{fontSize: '20px'}}>{value.DE_DESC}</FormLabel>
                            </Sheet>
                        );
                    }
                })}
            </RadioGroup>
        </>
    );
};