import * as React from 'react';
import { 
    Grid,
    Button
} from '@mui/material';

const QuoteContainer = React.forwardRef(function QuoteContainer(props, ref) {
    return (
            <Grid container className='quoteContainer'>
            {props.quotes.length > 0 ? (
                props.quotes.map((quoteItem, index) => (
                    <Grid container className='innerContainer' spacing={2}>
                        <Grid className='policyName'>
                            {quoteItem.Quote.Insurer}
                        </Grid>
                        <Grid className='policyPrice'>
                            £{quoteItem.Quote.Premium}
                        </Grid>
                        <Grid className='excess'>
                            <Grid class="policy">
                                Compulsory: £{quoteItem.Quote.PolicyExcess}
                            </Grid>
                            <Grid class="voluntary">
                                Voluntary: £{quoteItem.Quote.VoluntaryExcess}
                            </Grid>
                            <Grid class="total">
                                Total: £{[parseFloat(quoteItem.Quote.PolicyExcess) + parseFloat(quoteItem.Quote.VoluntaryExcess)]}
                            </Grid>
                        </Grid>
                        <Grid className='buyNow'>
                            <Button
                                variant="contained"
                                color="primary"
                                className='bordered'
                            >
                                Buy Now
                            </Button>
                        </Grid>
                    </Grid>
                ))
            ) : (
                <p>
                    We could not find any quotes at this time. Please try again later or alter
                    your information
                </p>
            )}
        </Grid>
    );
});

export default QuoteContainer;