import * as React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { 
    Grid,
    Typography,
    Button,
    CircularProgress,
    MenuItem,
    Alert
} from '@mui/material';
import { useField, useFormikContext } from "formik";

import DatePicker from "../../Components/DatePicker";
import { EmploymentRadio } from "../../Components/EmploymentRadio";
import { LicenceTypeRadio } from "../../Components/LicenceTypeRadio";
import { AutoCompelete } from "../../Components/Autocomplete";
import { CustomCheckbox } from "../../Components/Checkbox";
import { ContactTypeRadio } from "../../Components/ContactTypeRadio";

import titleList from '../../Data/MV/Title.json';
import MaritalStatusList from '../../Data/MV/MaritalStatus.json';
import HomeownerList from '../../Data/MV/Homeowner.json';
import GenderList from '../../Data/MV/Sex.json';
import OccupationList from '../../Data/MV/Occupation.json';
import EmployerList from '../../Data/MV/Employer.json';
import MainEmploymentCategoryList from '../../Data/MV/MainEmploymentCategory.json';

const ProposerStep = React.forwardRef(function ProposerStep(props, ref) {
    const [field] = useField(props);
    const { setFieldValue } = useFormikContext();
    const [contactAddressError, setContactAddressError] = React.useState(false);
    const [isCheckingAddress, setIsCheckingAddress] = React.useState(false);

    const autocompleteArray = (arr) => {
        var toReturn = [];

        arr.map((status) => {
            if (status.DE_SHOW === "Y") {
                toReturn.push({
                    'label' : status.DE_DESC,
                    'value': status.DE_CODE
                });
            }
        });

        return toReturn;
    };

    const checkAddress = async() => {
        setIsCheckingAddress(true);
        const result = await getAddress();

        if (result.status) {
            const expandedAddress = result.data;
        
            const addressLine1 = expandedAddress.house ? expandedAddress.house.replaceAll("+", " ") : "";
            const street = expandedAddress.street ? expandedAddress.street.replaceAll("+", " ") : "";
            const locality = expandedAddress.locality ? expandedAddress.locality.replaceAll("+", " ") : "";
            const town = expandedAddress.town ? expandedAddress.town.replaceAll("+", " ") : "";
            const county = expandedAddress.county ? expandedAddress.county.replaceAll("+", " ") : "";
            const postCode = expandedAddress.pCode ? expandedAddress.pCode.replaceAll("+", " ") : "";

            props.setContactAddress({
                addressLine1: street ? addressLine1 + " " + street : addressLine1,
                addressLine2: locality,
                city: town,
                county: county,
                postCode: postCode,
                firstLineStr: street ? addressLine1 + ", " + street : addressLine1 + ", " + locality,
                secondLineStr: town + ", " + county,
                thirdLineStr: postCode
            });
            setContactAddressError(false);
        }
        else {
            props.setContactAddress(false);
            setContactAddressError(true);
        }

        setIsCheckingAddress(false);
    };

    const getAddress = async() => {
        var response = {
            'msg' : 'No response from server',
            'status' : false
          };
  
          await fetch(`https://insurance.courio.co.uk/get/getAddress.php`, { 
              method: "POST",
              body: JSON.stringify({
                  line1: field.value.addressLine1,
                  postcode: field.value.addressPostcode,
                  api_key: process.env.REACT_APP_API_KEY
              }),
              headers: {
                  'Content-type': 'application/json; charset=UTF-8'
              }
          })
          .then((response) => response.json())
          .then((responseData) => {
              response = responseData;
          })
          .catch(error => console.warn(error));
      
          return response;
    }
    
    return (
        <Grid container spacing={0}>
            <Grid item container className='section no-margin-top' spacing={1}>
                <Grid item container>
                    <Typography variant="h6">
                        Personal Information
                    </Typography>
                </Grid>

                <Grid item container spacing={1}>
                    <Grid item md={2} xs={12}>
                        <Field 
                            variant="outlined" 
                            className={props.classes.fullWidth} 
                            component={TextField} 
                            name="proposerTitle" 
                            label="Title"
                            select
                            InputLabelProps={{shrink: true }}
                        >
                            <MenuItem disabled value="">Select Option</MenuItem>
                            {
                                titleList.map((title) => {
                                    if (title.DE_SHOW === "Y") {
                                        return (
                                            <MenuItem value={title.DE_CODE} key={title.DE_CODE}>
                                                {title.DE_DESC}
                                            </MenuItem>
                                        );
                                    }
                                })
                            }
                        </Field>
                    </Grid>
                </Grid>

                <Grid item container spacing={1}>
                    <Grid item md={6} xs={12}>
                        <Field 
                            variant="outlined" className={props.classes.fullWidth} component={TextField} name="proposerFirstName" label="First Name" />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Field variant="outlined" className={props.classes.fullWidth} component={TextField} name="proposerLastName" label="Last Name" />
                    </Grid>
                    
                    <Grid item md={6} xs={12}>
                        <Field 
                                variant="outlined" 
                                className={props.classes.fullWidth} 
                                component={TextField} 
                                name="proposerGender" 
                                label="Gender"
                                select
                                InputLabelProps={{shrink: true }}
                            >
                                <MenuItem disabled value="">Select Option</MenuItem>
                                {
                                    GenderList.map((status) => {
                                        if (status.DE_SHOW === "Y") {
                                            return (
                                                <MenuItem value={status.DE_CODE} key={status.DE_CODE}>
                                                    {status.DE_DESC}
                                                </MenuItem>
                                            );
                                        }
                                    })
                                }
                            </Field>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <DatePicker format="DD/MM/YYYY" name="proposerDOB" label="Date of birth"  />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Field 
                                variant="outlined" 
                                className={props.classes.fullWidth} 
                                component={TextField} 
                                name="proposerRelationshipStatus" 
                                label="Relationship status"
                                select
                                InputLabelProps={{shrink: true }}
                            >
                                <MenuItem disabled value="">Select Option</MenuItem>
                                {
                                    MaritalStatusList.map((status) => {
                                        if (status.DE_SHOW === "Y") {
                                            return (
                                                <MenuItem value={status.DE_CODE} key={status.DE_CODE}>
                                                    {status.DE_DESC}
                                                </MenuItem>
                                            );
                                        }
                                    })
                                }
                            </Field>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Field 
                            variant="outlined" 
                            className={props.classes.fullWidth} 
                            component={TextField} 
                            name="proposerHomeOwner" 
                            label="Is the proposer a home owner?"
                            select
                            InputLabelProps={{shrink: true }}
                        >
                            <MenuItem disabled value="">Select Option</MenuItem>
                            {
                                HomeownerList.map((status) => {
                                    if (status.DE_SHOW === "Y") {
                                        return (
                                            <MenuItem value={status.DE_CODE} key={status.DE_CODE}>
                                                {status.DE_DESC}
                                            </MenuItem>
                                        );
                                    }
                                })
                            }
                        </Field>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container className='section' spacing={1}>
                <Grid item container>
                    <Typography variant="h6">
                        Licence Information
                    </Typography>
                </Grid>

                <Grid item container spacing={1}>
                    <Grid item container spacing={1}>
                        <Grid item md={12} xs={12}>
                            <LicenceTypeRadio name="licenceType" label="What type of driving licence do you currently hold?" />
                        </Grid>
                    </Grid>

                    {field.value.licenceType != null ?
                        (() => {
                            switch(field.value.licenceType) {
                                case "A":
                                case "F":
                                    return(
                                        <Grid item container spacing={1} className='radioOptionContainer'>
                                            <Grid item md={6} xs={12}>
                                                <DatePicker format="DD/MM/YYYY" name="drivingLicenceStart" label="When did you gain this driving licence?" />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Field variant="outlined" className={props.classes.fullWidth} component={TextField} name="licenceNumber" label="Driving licence number (optional)" />
                                            </Grid>
                                        </Grid>
                                    );
                                ;
                                default:
                                    return(
                                        <Grid item container spacing={1} className='radioOptionContainer'>
                                            <Grid item md={6} xs={12}>
                                                <DatePicker format="DD/MM/YYYY" name="drivingLicenceStart" label="When did you gain this driving licence?" />
                                            </Grid>
                                        </Grid>
                                    );
                                ;
                            }
                        })()
                    : ''}
                </Grid>
            </Grid>

            <Grid item container className='section' spacing={1}>
                <Grid item container>
                    <Typography variant="h6">
                        Employment
                    </Typography>
                </Grid>

                <Grid item container spacing={1}>
                    <Grid item md={12} xs={12}>
                        <EmploymentRadio name="employmentStatus" label="What is your main employment status?" />
                    </Grid>
                </Grid>
                
                {field.value.employmentStatus != null ?
                    (() => {
                        switch(field.value.employmentStatus) {
                            case "E":
                            case "S":
                                return(
                                    <Grid item container spacing={1} className='radioOptionContainer'>
                                        <Grid item md={6} xs={12}>
                                            <AutoCompelete 
                                                options={autocompleteArray(OccupationList)}
                                                name={"proposerOccupation"} 
                                                label={"Occupation"}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <AutoCompelete 
                                                options={autocompleteArray(EmployerList)}
                                                name={"proposerOccupationIndustry"} 
                                                label={"Occupation industry"}
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            ;
                            case "O":
                                return(
                                    <Grid item container spacing={1} className='radioOptionContainer'>
                                        <Grid item md={6} xs={12}>
                                            <AutoCompelete 
                                                options={autocompleteArray(MainEmploymentCategoryList)}
                                                name={"proposerOccupationStatus"} 
                                                label={"Main employment status"}
                                            />
                                        </Grid>
                                    </Grid>
                                );  
                            ;
                        }
                    })()
                : ''}
            </Grid>

            <Grid item container className='section' spacing={1}>
                <Grid item container>
                    <Typography variant="h6">
                        How long has the proposer lived in the UK?
                    </Typography>
                </Grid>

                <Grid item container spacing={1}>
                    <Grid item md={6} xs={12}>
                        <DatePicker name="howLongInUK" label="Enter date: MM/YY" disabled={field.value.proposerUKSinceBirth} views={['month', 'year']}/>
                        <CustomCheckbox name="proposerUKSinceBirth" label="Since birth" />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container className='section' spacing={1}>
                <Grid item container>
                    <Typography variant="h6">
                        Contact Information
                    </Typography>
                </Grid>

                <Grid item container spacing={1}>
                    <Grid item md={6} xs={12}>
                        <Field type="text" variant="outlined" className={props.classes.fullWidth} component={TextField} name="telephoneNumber" label="Telephone Number" />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Field type="email" variant="outlined" className={props.classes.fullWidth} component={TextField} name="emailAddress" label="Email Address" />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <ContactTypeRadio label="What is your preferred contact method?" name="preferredContactMethod" />
                    </Grid>
                </Grid>

                <Grid item md={6} xs={12}>
                    <Typography variant="h6">
                        Let's find your address
                    </Typography>
                </Grid>

                {props.contactAddress === null ? 
                    <Grid container item md={12} xs={12} spacing={2}>
                        <Grid item md={4} xs={12}>
                            <Field variant="outlined" className={props.classes.fullWidth} component={TextField} name="addressLine1" label="House/flat number or name" />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Field variant="outlined" className={props.classes.fullWidth} component={TextField} name="addressPostcode" label="Postcode" />
                        </Grid>
                        <Grid item md={4} xs={12} className={props.classes.flex}>
                            <Button
                                startIcon={isCheckingAddress ? <CircularProgress style={{'color': '#07a9e1'}} size="1rem" /> : null}
                                disabled={isCheckingAddress}
                                variant="contained"
                                color="primary"
                                onClick={checkAddress}
                            >
                                {isCheckingAddress ? 'Checking Address...' : 'Check Address'}
                            </Button>
                        </Grid>
                    </Grid>
                : 
                    <Grid item container className={props.classes.houseDataContainer}>
                        <Grid item className='vehicleContainer' md={6} xs={12}>
                            <Grid container className='vehicleStats'>
                                <Grid item container md={3} xs={12} spacing={1} className='iconContainer'>
                                    <span className='icon'><i className='fa fa-home'></i></span>
                                </Grid>
                                <Grid item container md={9} xs={12} spacing={1}>
                                    <Grid item md={12} xs={12}>
                                        <Typography variant="h7">
                                            {props.contactAddress.firstLineStr}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Typography variant="h7" className={props.classes.vehicleData}>
                                            {props.contactAddress.secondLineStr}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Typography variant="h7">
                                            {props.contactAddress.thirdLineStr}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container className='vehicleActions'>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className='bordered smallBtn'
                                    onClick={e => props.setContactAddress(null) }
                                >
                                    Find another address
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item md={12} xs={12} className={props.classes.cbContainer}>
                            <CustomCheckbox name="addressConfirmed" label="Yes, that's my address" />
                        </Grid>
                    </Grid>
                    }

                {contactAddressError ? 
                    <Grid container className={props.classes.warningContainer}>
                        <Grid item md={12} xs={12}>
                            <Alert severity="warning">We regret to inform you that no property matching the provided postcode was found. Please ensure the accuracy of the postcode and attempt the search again. Should the issue persist, please contact us for further assistance.</Alert>
                        </Grid>
                    </Grid>
                : ''}
            </Grid>
        </Grid>
    );
});

export default ProposerStep;