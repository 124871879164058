import * as React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { 
    Grid,
    Typography,
    MenuItem,
} from '@mui/material';
import { useField } from "formik";
import { CoverTypeRadio } from "../../Components/CoverTypeRadio";
import DateTimePicker from "../../Components/DateTimePicker";
import { YesNoRadio } from '../../Components/YesNoRadio';

import NCBList from '../../Data/MV/NCB.json';
import NCBTypeList from '../../Data/MV/NCBType.json';
import UsageList from '../../Data/MV/Usage.json';
import DriverUsageFrequencyList from '../../Data/MV/DriverUsageFrequency.json';

const PolicyStep = React.forwardRef(function PolicyStep(props, ref) {
    const [field] = useField(props);

    return (
        <Grid container spacing={0}>
            <Grid item container className='section no-margin-top' spacing={1}>
                <Grid item container>
                    <Typography variant="h6">
                        Policy Information
                    </Typography>
                </Grid>

                <Grid item container spacing={1} rowSpacing={3}>
                    <Grid item container spacing={1}>
                        <Grid item md={12} xs={12}>
                            <CoverTypeRadio name="coverType" label="What type of cover do you need for your vehicle?" />
                        </Grid>
                    </Grid>

                    <Grid item container spacing={1}>
                        <Grid item md={6} xs={12}>
                            <Typography className="radioLabel">Policy start date and time</Typography>
                            <DateTimePicker name="policyStartDateTime" label="Policy start date and time" />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography className="radioLabel">How many years NCB does the main user of this vehicle hold?</Typography>
                            <Field 
                                variant="outlined" 
                                component={TextField} 
                                name="userNCB" 
                                label="Number of NCB years"
                                select
                                InputLabelProps={{shrink: true }}
                                fullWidth
                            >
                                <MenuItem disabled value="">Select Option</MenuItem>
                                {
                                    NCBList.map((title) => {
                                        if (title.DE_SHOW === "Y") {
                                            return (
                                                <MenuItem value={title.DE_CODE} key={title.DE_CODE}>
                                                    {title.DE_DESC}
                                                </MenuItem>
                                            );
                                        }
                                    })
                                }
                            </Field>
                        </Grid>
                    </Grid>

                    {field.value.userNCB !== "0" && field.value.userNCB !== '' ?
                        <Grid item container spacing={1}>
                            <Grid item container spacing={1}>
                                <Grid item md={6} xs={12}>
                                    <Typography className="radioLabel">What type of policy is this NCB from?</Typography>
                                    <Field 
                                        variant="outlined" 
                                        component={TextField} 
                                        name="userNCBFrom" 
                                        label="Select NCB from"
                                        select
                                        InputLabelProps={{shrink: true }}
                                        fullWidth
                                    >
                                        <MenuItem disabled value="">Select Option</MenuItem>
                                        {
                                            NCBTypeList.map((title) => {
                                                if (title.DE_SHOW === "Y") {
                                                    return (
                                                        <MenuItem value={title.DE_CODE} key={title.DE_CODE}>
                                                            {title.DE_DESC}
                                                        </MenuItem>
                                                    );
                                                }
                                            })
                                        }
                                    </Field>
                                </Grid>
                            </Grid>
                                
                            <Grid item container spacing={1}>
                                <Grid item md={12} xs={12}>
                                    <YesNoRadio name="protectNCB" label="Would the main user like to protect this NCB?"/>
                                </Grid>
                            </Grid>
                        </Grid>
                    : ''}
                </Grid>
            </Grid>
            <Grid item container className='section' spacing={1}>
                <Grid item container>
                    <Typography variant="h6">
                        Policy Usage
                    </Typography>
                </Grid>

                <Grid item container spacing={1} rowSpacing={3}>
                    <Grid item container spacing={1} rowSpacing={3}>
                        <Grid item md={6} xs={12}>
                            <Typography className="radioLabel">How will you use this vehicle?</Typography>
                            <Field 
                                variant="outlined" 
                                component={TextField} 
                                name="policyUsage" 
                                label="Vehicle usage type"
                                select
                                InputLabelProps={{shrink: true }}
                                fullWidth
                            >
                                <MenuItem disabled value="">Select Option</MenuItem>
                                {
                                    UsageList.map((title) => {
                                        if (title.DE_SHOW === "Y") {
                                            return (
                                                <MenuItem value={title.DE_CODE} key={title.DE_CODE}>
                                                    {title.DE_DESC}
                                                </MenuItem>
                                            );
                                        }
                                    })
                                }
                            </Field>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography className="radioLabel">What is the main driver usage frequency?</Typography>
                            <Field 
                                variant="outlined" 
                                component={TextField} 
                                name="policyDriverUsuageFrequency" 
                                label="Enter usage frequency"
                                select
                                InputLabelProps={{shrink: true }}
                                fullWidth
                            >
                                <MenuItem disabled value="">Select Option</MenuItem>
                                {
                                    DriverUsageFrequencyList.map((title) => {
                                        if (title.DE_SHOW === "Y") {
                                            return (
                                                <MenuItem value={title.DE_CODE} key={title.DE_CODE}>
                                                    {title.DE_DESC}
                                                </MenuItem>
                                            );
                                        }
                                    })
                                }
                            </Field>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography className="radioLabel">What is your total annual mileage for this vehicle?</Typography>
                            <Field type="number" variant="outlined" fullWidth component={TextField} name="policyTotalMileage" label="Enter mileage number" />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography className="radioLabel">How many of the annual mileage are for busiess use?</Typography>
                            <Field type="number" variant="outlined" fullWidth component={TextField} name="policyBusinessMileage" label="Enter mileage number" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

export default PolicyStep;