import { 
    Button
} from "@material-ui/core";
import React, { useContext } from "react";
import "./taskbar.css";

const Taskbar = (props) => {
    const handleClick = () => {

    };

    return (
        <div className="taskbar">
            <div className="taskbar-inner">
                <div className="logo">
                    <img src="courio_logo.png" />
                </div>
                <div className="retrieveQuote">
                    <Button variant="contained" color="primary" onClick={() => handleClick()}>Retrieve Quote</Button>
                </div>
            </div>
        </div>
    )
};

export default Taskbar;