/*
    Example Websites:
    - https://wecovr.quotezone.co.uk/van-insurance-ui/?step=1
    - https://www.prod.gocompare.com/journeys/van/vehicle?product=5

    Consider using radios to break up small selects
    = https://mui.com/joy-ui/react-radio-button/
*/

import { useState, Fragment } from 'react';
import { 
    Container,
    Paper,
} from '@mui/material';

import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from "notistack";
import { FormikStepper, FormikStep } from '../../Components/Stepper/quote';
import * as Yup from 'yup';
import "./quote.css";
import dayjs, { Dayjs } from 'dayjs';

/* Steps */

import PolicyStep from "../../Steps/Policy";
import VehicleStep from "../../Steps/Vehicle";
import ProposerStep from "../../Steps/Proposer";
import AdditionalDriversStep from "../../Steps/AdditionalDrivers";
import QuoteContainer from "../../Components/QuoteContainer";

export default function Quotes(props) {
    const styles = (theme) => ({
        paperContainer: {
            padding: 10,
            boxShadow: "none!important",
            background: "#f4f7f5!important"
        },
        flex: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignContent: 'center',
            justifyContent: 'space-around',
            alignItems: 'center',
        },
        fullWidth: {
            width: "100%!important"
        },
        title: {
            marginTop: "25px!important"
        },
        disclaimer: {
            fontSize: 10,
            marginTop: 25
        },
        vehicleDataContainer: {
            padding: "0px!important"
        },
        houseDataContainer: {
            padding: 15
        },
        cbContainer: {
            paddingTop: 15
        },
        vehicleData: {
            fontWeight: 900
        },
        vehicleRegData: {
            position: "relative",
            display: "flex",
            width: "156px",
            height: "40px",
            backgroundColor: "#f7d416",
            fontSize: "1em",
            cursor: "pointer",
            alignItems: "center",
        },
        vehicleRegHolder: {
            display: "flex",
            height: "100%",
            width: "20px",
            backgroundColor: "#168ef7",
            borderRadius: "3px 0 0 3px",
            marginRight: 10,
        },
        helperAction: {
            marginTop: 10
        },
        warningContainer: {
            paddingLeft: 15
        }
    });

    const { enqueueSnackbar } = useSnackbar();
    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const [vehicleData, setVehicleData] = useState(null);
    const [contactAddress, setContactAddress] = useState(null);
    const [quotes, setQuotes] = useState('');
    const [isTesting, setTesting] = useState(false);
    const [useTestData, setTestData] = useState(false);
    
    const onSubmit = async (data) => {
        var tmpData = {
            ...data,
            vehicleData: vehicleData,
            contactAddress: contactAddress,
            InceptDateTime: new Date(data.policyStartDateTime).toISOString(),
            proposerDateOfBirth: new Date(data.proposerDOB).toISOString().slice(0, 10)
        };
        
        const result = await getQuotes(tmpData);

        if (result && result.data) {
            let quotesArray = [];
        
            if (result.data.Content && Array.isArray(result.data.Content.Quotes)) {
                quotesArray = result.data.Content.Quotes;
            } 
            else if (result.data.Content && typeof result.data.Content.Quotes === 'object') {
                quotesArray = [result.data.Content.Quotes];
            }
        
            setQuotes(quotesArray);
        } else {
            setQuotes([]);
        }
    };

    const getQuotes = async(data) => {
        var response = {
          'msg' : 'No response from server',
          'status' : false
        };

        await fetch(isTesting === true ? `https://insurance.courio.co.uk/get2/quote.php` :  `https://insurance.courio.co.uk/get/quote.php`, { 
            method: "POST",
            body: JSON.stringify({
                data: data,
                api_key: process.env.REACT_APP_API_KEY
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
        .then((response) => response.json())
        .then((responseData) => {
            response = responseData;
        })
        .catch(error => console.warn(error));
    
        return response;
    };

    const notify = (flag, msg, delay = 10000) => {
        let type = "error";
  
        if (flag === 1) {
            type = "success";
        }
  
        enqueueSnackbar(msg, { variant: type, autoHideDuration: delay, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
        }});
    };

    return(
        <Container maxWidth="md">
            <Fragment>
                <Paper className={classes.paperContainer}>
                    <FormikStepper
                        initialValues={isTesting || useTestData ? {
                            vehicleRegistration: 'YX15NNU',
                            vehicleNotPurchasedYet: false,
                            vehicleConfirmed: false,
                            purchaseDate: '',
                            vehicleValue: '5000',
                            proposerTitle: 'Mr',
                            proposerFirstName: 'Bruce',
                            proposerLastName: 'Wayne',
                            proposerGender: 'M',
                            proposerDOB: dayjs("1993-03-19"),
                            proposerRelationshipStatus: 'M',
                            proposerHomeOwner: 'Y',
                            employmentStatus: 'E',
                            licenceType: 'F',
                            licenceNumber: '',
                            drivingLicenceStart: dayjs(),
                            proposerOccupation: '463',
                            proposerOccupationIndustry: '036',
                            proposerOccupationStatus: '',
                            //howLongInUK: null,
                            proposerUKSinceBirth: true,
                            emailAddress: 'richard@courio.co.uk',
                            telephoneNumber: '07496035259',
                            preferredContactMethod: null,
                            addressLine1: '6',
                            addressPostcode: 'DN220HL',
                            addressConfirmed: false,
                            coverType: "1",
                            policyStartDateTime: dayjs(),
                            userNCB: '0',
                            userNCBFrom: '',
                            protectNCB: '',
                            policyUsage: 'SDP',
                            policyDriverUsuageFrequency: 'M',
                            policyTotalMileage: 900,
                            policyBusinessMileage: 100
                        } : {
                            vehicleRegistration: '',
                            vehicleNotPurchasedYet: false,
                            vehicleConfirmed: false,
                            purchaseDate: '',
                            vehicleValue: '',
                            proposerTitle: '',
                            proposerFirstName: '',
                            proposerLastName: '',
                            proposerGender: '',
                            proposerDOB: dayjs(""),
                            proposerRelationshipStatus: '',
                            proposerHomeOwner: '',
                            employmentStatus: '',
                            licenceType: '',
                            licenceNumber: '',
                            drivingLicenceStart: dayjs(),
                            proposerOccupation: '',
                            proposerOccupationIndustry: '',
                            proposerOccupationStatus: '',
                            //howLongInUK: null,
                            proposerUKSinceBirth: true,
                            emailAddress: '',
                            telephoneNumber: '',
                            preferredContactMethod: null,
                            addressLine1: '',
                            addressPostcode: '',
                            addressConfirmed: false,
                            coverType: "",
                            policyStartDateTime: dayjs(),
                            userNCB: '',
                            userNCBFrom: '',
                            protectNCB: '',
                            policyUsage: '',
                            policyDriverUsuageFrequency: '',
                            policyTotalMileage: 0,
                            policyBusinessMileage: 0
                        }}
                        validateOnChange
                        validateOnBlur
                        onSubmit={onSubmit}
                    >
                        <FormikStep
                            label = "Vehicle"
                            validationSchema={Yup.object().shape({
                                vehicleRegistration: Yup.string()
                                .test('vrnTest', 'Vehicle Registration is not valid', (val, Schema) => {
                                    if (/(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/.test(val)) {
                                        return true;
                                    }
                                    else {
                                        return false;
                                    }
                                })
                                .test('vehicleDataTest', 'Please check your vehicle', (val, Schema) => {
                                    if (vehicleData === null) { return false; }
                                    else { 
                                        return true; 
                                    }
                                }),
                                vehicleConfirmed: Yup.boolean()
                                .test('isVehicleConfirmed', 'You must confirm your vehicle', (val, Schema) => {
                                    return val;
                                }),
                                purchaseDate: Yup.string()
                                .when("vehicleNotPurchasedYet", {
                                    is: false,
                                    then: Yup.string().required("Please enter a valid date"),
                                }),
                                vehicleNotPurchasedYet: Yup.boolean()
                                .test('vehiclePurchasedTest', 'You must check the not purchased checkbox', (val, Schema) => {
                                    return true;
                                }),
                                vehicleValue: Yup.number()
                                .min(50, "The value must be £50 or greater")
                                .max(999999, "The value must be £999,999 or less")
                                .required("Vehicle value is required")
                            })}
                        >
                            <VehicleStep 
                                classes={classes} 
                                setVehicleData={setVehicleData}
                                vehicleData={vehicleData}
                                notify={notify}
                                isTesting={isTesting}
                            />
                        </FormikStep>
                        <FormikStep
                            label = "Proposer"
                            validationSchema={Yup.object().shape({
                                proposerTitle: Yup.string()
                                .required("Title is required"),
                                proposerFirstName: Yup.string()
                                .max(100, 'First name must be 100 characters or less')
                                .required("First name is required"),
                                proposerLastName: Yup.string()
                                .max(100, 'Last name must be 100 characters or less')
                                .required("Last name is required"),
                                proposerGender: Yup.string()
                                .required("Gender is required"),
                                proposerDOB: Yup.string()
                                .required("Date of birth is required"),
                                proposerRelationshipStatus: Yup.string()
                                .required("Relationship status is required"),
                                proposerHomeOwner: Yup.string()
                                .required("Home ownership is required"),
                                employmentStatus: Yup.string()
                                .required("Employment Status is required")
                                .test('employmentStatusTest', 'Select Employment Status', (val) => {
                                    if (val) {
                                        return true;
                                    }
                                    return false;
                                }),
                                licenceType: Yup.string()
                                .required("Employment Status is required")
                                .test('licenceTypeTest', 'Select Licence type', (val) => {
                                    if (val) {
                                        return true;
                                    }
                                    return false;
                                }),
                                drivingLicenceStart: Yup.string()
                                .required("Driving licence start date is required"),
                                proposerOccupation: Yup.string()
                                .test('occupationTest', 'Occupation is required', (val, Schema) => {
                                    if (Schema.parent.employmentStatus === "E" || Schema.parent.employmentStatus === "S") {
                                        return val ? true : false;
                                    }
                                    else { 
                                        return true;
                                    }
                                }),
                                proposerOccupationIndustry: Yup.string()
                                .test('occupationIndustryTest', 'Occupation industry is required', (val, Schema) => {
                                    if (Schema.parent.employmentStatus === "E" || Schema.parent.employmentStatus === "S") {
                                        return val ? true : false;
                                    }
                                    else { 
                                        return true;
                                    }
                                }),
                                proposerOccupationStatus: Yup.string()
                                .test('occupationStatusTest', 'Occupation status is required', (val, Schema) => {
                                    if (Schema.parent.employmentStatus === "O") {
                                        return val ? true : false;
                                    }
                                    else { 
                                        return true;
                                    }
                                }),
                                howLongInUK: Yup.string()
                                .when("proposerUKSinceBirth", {
                                    is: false,
                                    then: Yup.string().required("Please enter a valid date"),
                                    otherwise: Yup.string()
                                }),
                                proposerUKSinceBirth: Yup.bool()
                                .test('proposerUKSinceBirthTest', 'Since birth input is invalid', (val, Schema) => {
                                    return true;
                                }),
                                emailAddress: Yup.string()
                                .required("Email Address is required")
                                .test('emailTest', 'Email address is not valid', (val, Schema) => {
                                    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(val)) {
                                        return true;
                                    }
                                    else {
                                        return false;
                                    }
                                }),
                                telephoneNumber: Yup.string()
                                .required("Telephone number is required")
                                .test('phoneTest', 'Telephone number is not valid', (val, Schema) => {
                                    if (/^\+?[0-9\s.-]+$/.test(val)) {
                                        return true;
                                    }
                                    else {
                                        return false;
                                    }
                                }),
                                addressLine1: Yup.string()
                                .max(250, "Address line 1 must be 250 characters or less"),
                                addressPostcode: Yup.string()
                                .max(12, "Postcode must be 12 characters or less")
                                .test('postcodeTest', 'Postcode is not valid', (val, Schema) => {
                                    if (/^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$/.test(val)) {
                                        return true;
                                    }
                                    else {
                                        return false;
                                    }
                                }),
                                addressConfirmed: Yup.boolean()
                                .test('isAddressConfirmed', 'You must confirm your address', (val, Schema) => {
                                    return val;
                                }),
                            })}
                        >
                            <ProposerStep
                                classes={classes}
                                contactAddress={contactAddress}
                                setContactAddress={setContactAddress}
                            />
                        </FormikStep>
                        <FormikStep
                            label = "Drivers"
                            validationSchema={Yup.object().shape({
                                additionalDrivers: Yup.string()
                                .required("Please select an option")
                            })}
                        >
                            <AdditionalDriversStep />
                        </FormikStep>
                        <FormikStep
                            label = "Cover"
                            validationSchema={Yup.object().shape({
                                coverType: Yup.string()
                                .required("Cover type is required")
                                .test('coverTypeTest', 'Select cover type', (val) => {
                                    if (val) {
                                        return true;
                                    }
                                    return false;
                                }),
                                policyStartDateTime: Yup.string()
                                .required("Policy start date and time is required"),
                                userNCB: Yup.string()
                                .required("No claims bonus years is required"),
                                userNCBFrom: Yup.string()
                                .when("userNCB", {
                                    is: (value) => value === '0',
                                    then: Yup.string(),
                                    otherwise: Yup.string().required("Please enter a NCB type"),
                                }),
                                protectNCB: Yup.string()
                                .when("userNCB", {
                                    is: (value) => value === '0',
                                    then: Yup.string(),
                                    otherwise: Yup.string().required("Please select whether you want to keep the NCB or not"),
                                }),
                                policyUsage: Yup.string()
                                .required("Policy usage is required"),
                                policyDriverUsuageFrequency: Yup.string()
                                .required("Policy driver usage frequency is required"),
                                policyTotalMileage: Yup.number()
                                .required("Policy total mileage is required"),
                                policyBusinessMileage: Yup.number()
                                .required("Policy total business mileage is required")
                            })}
                        >
                            <PolicyStep />
                        </FormikStep>
                        <FormikStep
                            label = "Quotes"
                        >
                            <QuoteContainer quotes={quotes} />
                        </FormikStep>
                    </FormikStepper>
                    <p className={classes.disclaimer}>Disclaimer: You are responsible for providing accurate information in this van insurance quotation form. We are not liable for any consequences arising from inaccuracies, and the insurance policy may be voided due to false information. Please review and verify all details before submission. Seek advice if needed.</p>
                </Paper>
            </Fragment>
        </Container>
    );
}