import React from "react";
import { FC } from "react";
import { useField, useFormikContext } from "formik";
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import { 
    Autocomplete 
} from '@mui/material';

export const AutoCompelete: FC = (props: any) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    interface properties {
        value: string;
        label: string;
    }

    return (
        <>
            <Autocomplete
                options={props.options}
                disablePortal
                value={props.options.find(v => v.value === field.value) || null}
                onChange={(event: any, newValue: properties) => {
                    newValue != null ? setFieldValue(field.name, newValue.value) : setFieldValue(field.name, '');
                }}
                renderInput={params => (
                    <Field 
                        component={TextField} 
                        {...params} 
                        name={props.name}
                        label={props.label}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
        </>
    );
};