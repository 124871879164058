import Quotes from "./Pages/Quotes";
import Taskbar from "./Components/Taskbar/";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import './app.css';

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#9e9e9e",
      contrastText: "#ffffff",
    }
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} classes={{containerRoot: 'snackbar'}}>
            <BrowserRouter>
              <Taskbar />
              <div className="container">
                <Routes>
                  <Route path="/" element={<Quotes />} />
                </Routes>
              </div>
            </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
