import React, { useState, useRef } from 'react';
import { Form, Formik, FormikConfig, FormikValues, useFormikContext } from 'formik';
import { 
    Button, 
    CircularProgress, 
    Grid, 
    Step, 
    StepLabel, 
    Stepper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography
} from "@material-ui/core";
import ReactRouterPrompt from 'react-router-prompt';
import Reference from 'yup/lib/Reference';

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>;
}

export function FormikStepper({ children, ...props }: FormikConfig<FormikValues>) {
  const tmpArr = children || [];
  const childrenArray = [tmpArr[0], tmpArr[1], tmpArr[2], tmpArr[3], tmpArr[4]];
  const progressArr = [tmpArr[0], tmpArr[1], tmpArr[2], tmpArr[3]];
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  function isLastProgressStep() {
    return step === progressArr.length - 1;
  }

  function getTitle(step) {
    var toReturn = "";

    switch(step) {
      case 0:
        toReturn = 'Let\'s find your vehicle';
      break;
      case 1:
        toReturn = 'Proposer Details';
      break;
      case 2:
        toReturn = 'Additional Drivers';
      break;
      case 3:
        toReturn = 'Your Policy';
      break;
      case 4:
        toReturn = 'Your Quotes';
      break;
    }

    return toReturn;
  }

  function getIcon(index, step) {
    var toReturn = "<i class='fa fa-car'></i>";

    switch(index) {
      case 0:
        toReturn = "<i class='fa fa-magnifying-glass'></i>";
      break;
      case 1:
        toReturn = "<i class='fa fa-car'></i>";
      break;
      case 2:
        toReturn = "<i class='fa fa-user-pen'></i>";
      break;
      case 3:
        toReturn = "<i class='fa fa-list-check'></i>";
      break;
    }

    if (step > index) {
      toReturn = "<i class='fa fa-check'></i>";
    }

    return <span dangerouslySetInnerHTML={{__html:  toReturn}}></span>;
  }

  const PromptIfDirty = () => {
    const formik = useFormikContext();

    return (
      <ReactRouterPrompt when={formik.dirty && formik.submitCount === 0}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            open={isActive}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Unsaved Changes"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                You are about to navigate away, which means you could lose your progress. Would you like to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" className='error-button' onClick={onCancel}>Cancel</Button>
              <Button variant="contained" className="primary-button2" onClick={onConfirm}>Continue</Button>
            </DialogActions>
          </Dialog>
        )}
      </ReactRouterPrompt>
    );
  };

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastProgressStep()) {
          await props.onSubmit(values, helpers);
          setStep((s) => s + 1);
          setCompleted(true);
        } 
        else {
          setStep((s) => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form autoComplete="on">
          <PromptIfDirty/>
            <div className='step-header'>
              <Typography variant="h4">
                  {getTitle(step)}
              </Typography>
              <Stepper alternativeLabel activeStep={step}>
                {progressArr.map((child, index) => (
                  <Step key={child.props.label} completed={step > index || completed}>
                    <StepLabel
                      icon={getIcon(index, step)}
                    >
                      <span dangerouslySetInnerHTML={{__html: child.props.label.replaceAll("& ", "<br/>& ")}}></span>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>

          {currentChild}
              
          <hr className='stepper-seperator'/>

          <Grid container spacing={2} className="btn-container">
            {step > 0 ? (
              <Grid item >
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="secondary"
                  onClick={() => setStep((s) => s - 1)}
                >
                  Back
                </Button>
              </Grid>
            ) : null}
            {isValid  && step != 4 ?
            <Grid item>
              <Button
                startIcon={isSubmitting ? <CircularProgress style={{'color': '#fff'}} size="1rem" /> : null}
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
              >
                {isSubmitting ? 'Fetching Quotes...' : isLastProgressStep() ? 'Get Quotes' : 'Continue'}
              </Button>
            </Grid>
            : null}
          </Grid>
        </Form>
      )}
    </Formik>
  );
}