import { FC } from "react";
import { useField, useFormikContext } from "formik";
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';

export const VehicleSearch: FC = (props: any) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    return (
        <>
            <Field 
                variant="outlined" 
                className="registration" 
                component={TextField}
                {...props}
                onKeyUp={event => {
                    setFieldValue(field.name, event.target.value.toUpperCase());
                }}
                type="text"
                fullWidth
            />
        </>
    );
};
